<template>
  <div>
    <v-form ref="timeOffModal">
      <v-card v-if="isModeAdd" class="cr-modal">
        <v-card-title>
          <h2>Add Time Off</h2>
        </v-card-title>
        <v-card-text>
          <v-layout column>
            <label>Description</label>
            <textarea
              id="driver-time-off-form-textarea-description"
              v-model="timeOffFormData.description"
              :rules="[
                isRequired(true, isNotEmpty, {
                  req: 'Description Is Required',
                  error: 'Description Is Required',
                }),
              ]"
              class="description-input"
              placeholder="e.g. Vacation, PTO"
              name="Description"
              label="Description"
              cols="10"
              rows="5"
            />
          </v-layout>
          <v-layout row align-center justify-space-between padded class="mt-4">
            <v-layout row align-center padded>
              <v-flex>
                <CRInput
                  id="time-off-form-start-date"
                  v-model="timeOffFormData.startDate"
                  :rules="[
                    isRequired(true, isNotEmpty, {
                      req: 'Start Date Is Required',
                      error: 'Start Date Is Required',
                    }),
                  ]"
                  label="Start Date"
                  type="date"
                  @input="
                    () => {
                      timeOffFormData.startTime =
                        timeOffFormData.startTime || '12:00'
                    }
                  "
                />
              </v-flex>
              <v-flex>
                <CRInput
                  id="time-off-form-start-time"
                  v-model="timeOffFormData.startTime"
                  :rules="[
                    isRequired(true, isNotEmpty, {
                      req: 'Start Time Is Required',
                      error: 'Start Time Is Required',
                    }),
                  ]"
                  label="Start Time"
                  type="time"
                />
              </v-flex>
            </v-layout>
            <v-layout row align-center padded>
              <v-flex>
                <CRInput
                  id="time-off-form-end-date"
                  v-model="timeOffFormData.endDate"
                  :rules="[
                    isRequired(true, isNotEmpty, {
                      req: 'End Date Is Required',
                      error: 'End Date Is Required',
                    }),
                  ]"
                  label="End Date"
                  type="date"
                  @input="
                    () => {
                      timeOffFormData.endTime =
                        timeOffFormData.endTime || '12:00'
                    }
                  "
                />
              </v-flex>
              <v-flex>
                <CRInput
                  id="time-off-form-end-time"
                  v-model="timeOffFormData.endTime"
                  :rules="[
                    isRequired(true, isNotEmpty, {
                      req: 'End Time Is Required',
                      error: 'End Time Is Required',
                    }),
                  ]"
                  label="End Time"
                  type="time"
                />
              </v-flex>
            </v-layout>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-end>
            <div class="button-cont">
              <div style="margin-right: -1.5%">
                <v-btn
                  id="driver-time-off-button-cancel"
                  color="grey darken"
                  outline
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  id="driver-time-off-add-time-off"
                  color="primary"
                  @click="save"
                >
                  Add Time Off
                </v-btn>
              </div>
            </div>
          </v-layout>
        </v-card-actions>
      </v-card>
      <v-card v-else-if="isModeEdit" class="cr-modal">
        <v-card-title>
          <h2>Edit Time Off</h2>
        </v-card-title>
        <v-card-text>
          <v-layout column>
            <label>Description</label>
            <textarea
              id="driver-time-off-text-area-description"
              v-model="timeOffFormData.description"
              :rules="[
                isRequired(true, isNotEmpty, {
                  req: 'Description Is Required',
                  error: 'Description Is Required',
                }),
              ]"
              class="description-input"
              placeholder="e.g. Vacation, PTO"
              name="Description"
              cols="10"
              rows="5"
            />

            <v-layout
              row
              align-center
              justify-space-between
              padded
              class="mt-4"
            >
              <v-layout row align-center padded>
                <v-flex>
                  <CRInput
                    id="time-off-form-start-date"
                    v-model="timeOffFormData.startDate"
                    :rules="[
                      isRequired(true, isNotEmpty, {
                        req: 'Start Date Is Required',
                        error: 'Start Date Is Required',
                      }),
                    ]"
                    label="Start Date"
                    type="date"
                    @input="
                      () => {
                        timeOffFormData.startTime =
                          timeOffFormData.startTime || '12:00'
                      }
                    "
                  />
                </v-flex>
                <v-flex>
                  <CRInput
                    id="time-off-form-start-time"
                    v-model="timeOffFormData.startTime"
                    :rules="[
                      isRequired(true, isNotEmpty, {
                        req: 'Start Time Is Required',
                        error: 'Start Time Is Required',
                      }),
                    ]"
                    label="Start Time"
                    type="time"
                  />
                </v-flex>
              </v-layout>
              <v-layout row align-center padded>
                <v-flex>
                  <CRInput
                    id="time-off-form-end-date"
                    v-model="timeOffFormData.endDate"
                    :rules="[
                      isRequired(true, isNotEmpty, {
                        req: 'End Date Is Required',
                        error: 'End Date Is Required',
                      }),
                    ]"
                    label="End Date"
                    type="date"
                    @input="
                      () => {
                        timeOffFormData.endTime =
                          timeOffFormData.endTime || '12:00'
                      }
                    "
                  />
                </v-flex>
                <v-flex>
                  <CRInput
                    id="time-off-form-end-time"
                    v-model="timeOffFormData.endTime"
                    :rules="[
                      isRequired(true, isNotEmpty, {
                        req: 'End Time Is Required',
                        error: 'End Time Is Required',
                      }),
                    ]"
                    label="End Time"
                    type="time"
                  />
                </v-flex>
              </v-layout>
            </v-layout>
          </v-layout>
        </v-card-text>

        <v-card-actions>
          <v-layout justify-end>
            <div class="button-cont">
              <div style="margin-right: -1.5%">
                <v-btn color="grey darken" outline @click="close">Cancel</v-btn>
                <v-btn color="primary" @click="save">Update Time Off</v-btn>
              </div>
            </div>
          </v-layout>
        </v-card-actions>
      </v-card>
      <v-card v-else-if="isModeDelete" class="cr-modal">
        <v-card-title>
          <h2>Delete Time Off</h2>
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete this time off request?
        </v-card-text>
        <v-card-actions>
          <v-layout justify-end class="mt-2">
            <div class="button-cont">
              <div style="margin-right: -1.5%">
                <v-btn color="grey darken" outline @click="close">Cancel</v-btn>
                <v-btn class="delete-target" @click="save">
                  Delete Time Off
                </v-btn>
              </div>
            </div>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { authComputed } from '@/state/helpers'
import { isNotEmpty, isRequired } from '@/utils/validators'
import { DateTime } from 'luxon'
import { getDatetimeFromDateAndTimeStrings } from '@/utils/time'

export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
    userId: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'add',
    },
  },
  data() {
    return {
      timeOffFormData: {
        driverTimeOffRequestId: null,
        description: '',
        requestedOn: null,
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        statusId: 1,
        userId: null,
        companyId: null,
        isActive: true,
      },
    }
  },
  computed: {
    ...authComputed,
    driverFullName() {
      if (!this.user) {
        return ''
      }
      return `${this.user?.firstName} ${this.user?.lastName}`
    },
    isModeAdd() {
      return this.mode === 'add'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    isModeDelete() {
      return this.mode === 'delete'
    },
  },
  async mounted() {
    if (this.isModeAdd) {
      this.timeOffFormData.userId = this.userId
      this.timeOffFormData.companyId = this.user.companyId
    }

    if (this.isModeEdit) {
      Object.assign(this.timeOffFormData, this.$attrs.timeOffFormData.data)
      this.timeOffFormData.startTime = DateTime.fromISO(
        this.timeOffFormData.startDate
      ).toLocaleString(DateTime.TIME_24_SIMPLE)
      this.timeOffFormData.endTime = DateTime.fromISO(
        this.timeOffFormData.endDate
      ).toLocaleString(DateTime.TIME_24_SIMPLE)
      this.timeOffFormData.startDate = DateTime.fromISO(
        this.timeOffFormData.startDate
      )
      this.timeOffFormData.endDate = DateTime.fromISO(
        this.timeOffFormData.endDate
      )
    }

    if (this.isModeDelete) {
      Object.assign(this.timeOffFormData, this.$attrs.timeOffFormData.data)
    }
  },
  methods: {
    isRequired,
    isNotEmpty,
    async save() {
      const formIsValid = this.$refs.timeOffModal.validate()
      if (!formIsValid) {
        return
      }
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        ? Intl.DateTimeFormat().resolvedOptions().timeZone
        : ''
      // Factor time into startDate
      this.timeOffFormData.startDate = getDatetimeFromDateAndTimeStrings(
        this.timeOffFormData.startDate,
        this.timeOffFormData.startTime,
        userTimeZone
      )
      // Factor time into endDate
      this.timeOffFormData.endDate = getDatetimeFromDateAndTimeStrings(
        this.timeOffFormData.endDate,
        this.timeOffFormData.endTime,
        userTimeZone
      )

      const payload = this.timeOffFormData

      if (this.isModeDelete) {
        await this.$store.dispatch(
          'driverTimeOffRequests/deleteRequest',
          payload.driverTimeOffRequestId
        )
        this.close()
      }

      if (this.isModeAdd) {
        delete payload.driverTimeOffRequestId
        payload.requestedOn = DateTime.local().toISO()
      }
      payload.startDate = DateTime.fromISO(payload.startDate).toISO()
      payload.endDate = DateTime.fromISO(payload.endDate).toISO()
      if (this.isModeAdd) {
        await this.$store.dispatch('driverTimeOffRequests/createRequest', {
          payload: payload,
        })
      }

      if (this.isModeEdit) {
        delete payload.status
        await this.$store.dispatch('driverTimeOffRequests/editRequest', payload)
      }

      this.close()
      if (this.isModeAdd) {
        this.$store.dispatch('app/showAlert', {
          message: 'Time off request added.',
        })
      } else if (this.isModeEdit) {
        this.$store.dispatch('app/showAlert', {
          message: 'Time off request updated.',
        })
      } else if (this.isModeDelete) {
        this.$store.dispatch('app/showAlert', {
          message: 'Time off request deleted.',
        })
      }
      this.$store.dispatch('driverTimeOffRequests/causeRefresh')
    },
    close() {
      this.$store.dispatch('app/closeDialog')
    },
  },
}
</script>
<style lang="scss" scoped>
.dialog-container {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 500px;
  padding: 4% 8% 4% 8%;
  overflow-y: hidden;
  border-radius: 5px;

  .label-cont {
    display: flex;
    justify-content: space-between;
  }

  .description-input {
    height: 100%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: $blue-light;
  }

  .button-cont {
    display: flex;
    justify-content: flex-end;
  }

  label {
    margin: 0;
    font-size: 20px;
    line-height: 1.42857;
  }

  .close {
    float: right;
    padding: 0;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: $gray-base;
    text-shadow: 0 1px 0 $white;
    cursor: pointer;
    background: transparent;
    border: 0;
    opacity: 0.2;
  }

  .close:hover {
    opacity: 0.4;
  }
}

.description-input {
  height: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: $blue-light;
}

.delete-target {
  color: $white;
  background-color: $red !important;
}
</style>
