var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{ref:"timeOffModal"},[(_vm.isModeAdd)?_c('v-card',{staticClass:"cr-modal"},[_c('v-card-title',[_c('h2',[_vm._v("Add Time Off")])]),_c('v-card-text',[_c('v-layout',{attrs:{"column":""}},[_c('label',[_vm._v("Description")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.timeOffFormData.description),expression:"timeOffFormData.description"}],staticClass:"description-input",attrs:{"id":"driver-time-off-form-textarea-description","rules":[
              _vm.isRequired(true, _vm.isNotEmpty, {
                req: 'Description Is Required',
                error: 'Description Is Required',
              }),
            ],"placeholder":"e.g. Vacation, PTO","name":"Description","label":"Description","cols":"10","rows":"5"},domProps:{"value":(_vm.timeOffFormData.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.timeOffFormData, "description", $event.target.value)}}})]),_c('v-layout',{staticClass:"mt-4",attrs:{"row":"","align-center":"","justify-space-between":"","padded":""}},[_c('v-layout',{attrs:{"row":"","align-center":"","padded":""}},[_c('v-flex',[_c('CRInput',{attrs:{"id":"time-off-form-start-date","rules":[
                  _vm.isRequired(true, _vm.isNotEmpty, {
                    req: 'Start Date Is Required',
                    error: 'Start Date Is Required',
                  }),
                ],"label":"Start Date","type":"date"},on:{"input":() => {
                    _vm.timeOffFormData.startTime =
                      _vm.timeOffFormData.startTime || '12:00'
                  }},model:{value:(_vm.timeOffFormData.startDate),callback:function ($$v) {_vm.$set(_vm.timeOffFormData, "startDate", $$v)},expression:"timeOffFormData.startDate"}})],1),_c('v-flex',[_c('CRInput',{attrs:{"id":"time-off-form-start-time","rules":[
                  _vm.isRequired(true, _vm.isNotEmpty, {
                    req: 'Start Time Is Required',
                    error: 'Start Time Is Required',
                  }),
                ],"label":"Start Time","type":"time"},model:{value:(_vm.timeOffFormData.startTime),callback:function ($$v) {_vm.$set(_vm.timeOffFormData, "startTime", $$v)},expression:"timeOffFormData.startTime"}})],1)],1),_c('v-layout',{attrs:{"row":"","align-center":"","padded":""}},[_c('v-flex',[_c('CRInput',{attrs:{"id":"time-off-form-end-date","rules":[
                  _vm.isRequired(true, _vm.isNotEmpty, {
                    req: 'End Date Is Required',
                    error: 'End Date Is Required',
                  }),
                ],"label":"End Date","type":"date"},on:{"input":() => {
                    _vm.timeOffFormData.endTime =
                      _vm.timeOffFormData.endTime || '12:00'
                  }},model:{value:(_vm.timeOffFormData.endDate),callback:function ($$v) {_vm.$set(_vm.timeOffFormData, "endDate", $$v)},expression:"timeOffFormData.endDate"}})],1),_c('v-flex',[_c('CRInput',{attrs:{"id":"time-off-form-end-time","rules":[
                  _vm.isRequired(true, _vm.isNotEmpty, {
                    req: 'End Time Is Required',
                    error: 'End Time Is Required',
                  }),
                ],"label":"End Time","type":"time"},model:{value:(_vm.timeOffFormData.endTime),callback:function ($$v) {_vm.$set(_vm.timeOffFormData, "endTime", $$v)},expression:"timeOffFormData.endTime"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-layout',{attrs:{"justify-end":""}},[_c('div',{staticClass:"button-cont"},[_c('div',{staticStyle:{"margin-right":"-1.5%"}},[_c('v-btn',{attrs:{"id":"driver-time-off-button-cancel","color":"grey darken","outline":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"id":"driver-time-off-add-time-off","color":"primary"},on:{"click":_vm.save}},[_vm._v(" Add Time Off ")])],1)])])],1)],1):(_vm.isModeEdit)?_c('v-card',{staticClass:"cr-modal"},[_c('v-card-title',[_c('h2',[_vm._v("Edit Time Off")])]),_c('v-card-text',[_c('v-layout',{attrs:{"column":""}},[_c('label',[_vm._v("Description")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.timeOffFormData.description),expression:"timeOffFormData.description"}],staticClass:"description-input",attrs:{"id":"driver-time-off-text-area-description","rules":[
              _vm.isRequired(true, _vm.isNotEmpty, {
                req: 'Description Is Required',
                error: 'Description Is Required',
              }),
            ],"placeholder":"e.g. Vacation, PTO","name":"Description","cols":"10","rows":"5"},domProps:{"value":(_vm.timeOffFormData.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.timeOffFormData, "description", $event.target.value)}}}),_c('v-layout',{staticClass:"mt-4",attrs:{"row":"","align-center":"","justify-space-between":"","padded":""}},[_c('v-layout',{attrs:{"row":"","align-center":"","padded":""}},[_c('v-flex',[_c('CRInput',{attrs:{"id":"time-off-form-start-date","rules":[
                    _vm.isRequired(true, _vm.isNotEmpty, {
                      req: 'Start Date Is Required',
                      error: 'Start Date Is Required',
                    }),
                  ],"label":"Start Date","type":"date"},on:{"input":() => {
                      _vm.timeOffFormData.startTime =
                        _vm.timeOffFormData.startTime || '12:00'
                    }},model:{value:(_vm.timeOffFormData.startDate),callback:function ($$v) {_vm.$set(_vm.timeOffFormData, "startDate", $$v)},expression:"timeOffFormData.startDate"}})],1),_c('v-flex',[_c('CRInput',{attrs:{"id":"time-off-form-start-time","rules":[
                    _vm.isRequired(true, _vm.isNotEmpty, {
                      req: 'Start Time Is Required',
                      error: 'Start Time Is Required',
                    }),
                  ],"label":"Start Time","type":"time"},model:{value:(_vm.timeOffFormData.startTime),callback:function ($$v) {_vm.$set(_vm.timeOffFormData, "startTime", $$v)},expression:"timeOffFormData.startTime"}})],1)],1),_c('v-layout',{attrs:{"row":"","align-center":"","padded":""}},[_c('v-flex',[_c('CRInput',{attrs:{"id":"time-off-form-end-date","rules":[
                    _vm.isRequired(true, _vm.isNotEmpty, {
                      req: 'End Date Is Required',
                      error: 'End Date Is Required',
                    }),
                  ],"label":"End Date","type":"date"},on:{"input":() => {
                      _vm.timeOffFormData.endTime =
                        _vm.timeOffFormData.endTime || '12:00'
                    }},model:{value:(_vm.timeOffFormData.endDate),callback:function ($$v) {_vm.$set(_vm.timeOffFormData, "endDate", $$v)},expression:"timeOffFormData.endDate"}})],1),_c('v-flex',[_c('CRInput',{attrs:{"id":"time-off-form-end-time","rules":[
                    _vm.isRequired(true, _vm.isNotEmpty, {
                      req: 'End Time Is Required',
                      error: 'End Time Is Required',
                    }),
                  ],"label":"End Time","type":"time"},model:{value:(_vm.timeOffFormData.endTime),callback:function ($$v) {_vm.$set(_vm.timeOffFormData, "endTime", $$v)},expression:"timeOffFormData.endTime"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-layout',{attrs:{"justify-end":""}},[_c('div',{staticClass:"button-cont"},[_c('div',{staticStyle:{"margin-right":"-1.5%"}},[_c('v-btn',{attrs:{"color":"grey darken","outline":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v("Update Time Off")])],1)])])],1)],1):(_vm.isModeDelete)?_c('v-card',{staticClass:"cr-modal"},[_c('v-card-title',[_c('h2',[_vm._v("Delete Time Off")])]),_c('v-card-text',[_vm._v(" Are you sure you want to delete this time off request? ")]),_c('v-card-actions',[_c('v-layout',{staticClass:"mt-2",attrs:{"justify-end":""}},[_c('div',{staticClass:"button-cont"},[_c('div',{staticStyle:{"margin-right":"-1.5%"}},[_c('v-btn',{attrs:{"color":"grey darken","outline":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"delete-target",on:{"click":_vm.save}},[_vm._v(" Delete Time Off ")])],1)])])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }